import axios from 'axios'

axios.defaults.xsrfCookieName = '_csrf'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

class RequestService {
  constructor (options = {}) {
    this.axios = options.axios || axios
  }

  get (url, options = {}) {
    const { config = {} } = options
    return this.axios.get(url, config)
  }

  post (url, body = {}, options = {}) {
    const { config = {} } = options
    return this.axios.post(url, body, config)
  }

  put (url, body = {}, options = {}) {
    const { config = {} } = options
    return this.axios.put(url, body, config)
  }

  patch (url, body = {}, options = {}) {
    const { config = {} } = options
    return this.axios.patch(url, body, config)
  }

  delete (url, body = {}, options = {}) {
    const { config = {} } = options
    return this.axios.delete(url, body, config)
  }

  head (...args) {
    return this.axios.head(...args)
  }
}

export default RequestService
