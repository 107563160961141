import { format, parseISO, intervalToDuration, formatDuration } from 'date-fns'
import { TIMESTAMP_FORMAT } from '../../shared/constants/dates'

export default {
  User: {
    taggingId ({ email }) {
      const userName = email.toLowerCase().split('@')[0]
      if (userName.indexOf('.') > -1) {
        const [firstName, lastName] = userName.split('.')
        return `${firstName.charAt(0)}${lastName}`
      } else {
        return `${userName}`
      }
    },
  },

  Import: {
    displayedFilename ({ provider, uploadDate }) {
      if (!provider || !uploadDate) {
        return null
      }
      const datetime = format(parseISO(uploadDate), TIMESTAMP_FORMAT)
      return `${provider}_${datetime}`
    },
    processingTime ({ processStartDate, processEndDate }) {
      if (!processStartDate || !processEndDate) {
        return null
      }
      const duration = intervalToDuration({
        start: parseISO(processStartDate),
        end: parseISO(processEndDate),
      })
      return formatDuration(duration)
        .replace(/minutes?/ig, 'min') // date-fns' "formatDuration" method unfortunately does not currently allow for custom unit values
        .replace(/seconds?/ig, 'sec')
    },
    totalRecordsSuccessful ({ totalRecords, totalRecordsFailed }) {
      return totalRecords - totalRecordsFailed
    },
    totalPlansSuccessful ({ totalPlans, totalPlansFailed }) {
      return totalPlans - totalPlansFailed
    },
  },
}
