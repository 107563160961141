import operationsRoutes from './operations'
import dataRoutes from './data'
import configurationsRoute from './configurations'
import dashboardRoute from './dashboard'
import unauthorizedRoute from './unauthorized-route'

/**
 * The route optional "meta" object can include the following information:
 *
 * title: the title of the route
 * main: whether to include the route in the top-level navigation
 * hideNavigation: whether to hide the top-level navigation bar from the route or not
 * allowedUserRoles: a list of allowed user roles. If omitted, it is assumed any role is allowed
 */

export default ({ store } = {}) => [
  { path: '/', name: 'home', redirect: { name: 'dashboard' } },
  unauthorizedRoute(),
  ...dashboardRoute(),
  ...operationsRoutes(),
  ...dataRoutes(),
  ...configurationsRoute(),
  {
    path: '*',
    redirect: '/dashboard',
  },
]
