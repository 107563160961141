import dataPlansRoute from './data-plans-route'
import dataClientsRoute from './data-clients-route'
import { Roles } from '../../../../shared/constants'

export default () => ({
  path: '/data',
  name: 'data',
  component: () => import(/* webpackChunkName: "data-root" */ '../../../views/Data/DataRoot.vue'),
  redirect: { name: 'data-plans' },
  children: [
    dataPlansRoute(),
    dataClientsRoute(),
  ],
  meta: {
    main: true,
    title: 'Data',
    allowedUserRoles: [
      Roles.RADAR_ADMIN,
      Roles.RADAR_OPS,
      Roles.RADAR_TEST,
      Roles.RADAR_VIEW,
    ],
  },
})
