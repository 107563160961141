/**
 * Intersection Observer API: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 *
 * Directive API
 * Argument:
 * Function | { handler: Function, options?: Object }
 * The function to invoke when the targeted element is intersected. Will return "entries" and "observer"
 *
 * Usage:
 * With just a handler: v-intersect="onIntersect"
 * With a handler and options: v-intersect="{ handler: onIntersect, options: { threshold: 1.0 } }"
 *
 * Heavily referenced Vuetify's directive: https://vuetifyjs.com/en/directives/intersect
*/
export default {
  inserted (el, binding) {
    const { value } = binding
    const { handler, options } = typeof value === 'object' ? value : { handler: value, options: {} }
    const observer = new IntersectionObserver(handler, options)
    el._observe = { observer }
    observer.observe(el)
  },
  unbind (el) {
    el._observe.observer.unobserve(el)
    delete el._observe
  },
}
