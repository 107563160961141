import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { apolloProvider, apolloClient } from './graqhql'
import registerDirectives from './directives'
import registerPlugins from './plugins'

registerDirectives(Vue)
registerPlugins(Vue)

Vue.config.productionTip = false

new Vue({
  apolloProvider,
  router: router({ store: apolloClient }),
  render: h => h(App),
}).$mount('#app')
