import { Roles } from '../../../../shared/constants'

export default () => ({
  path: 'clients',
  name: 'data-clients',
  component: () => import(/* webpackChunkName: "data-clients" */ '../../../views/Data/DataClients'),
  meta: {
    title: 'Clients',
    allowedUserRoles: [Roles.RADAR_OPS, Roles.RADAR_TEST, Roles.RADAR_VIEW],
  },
})
