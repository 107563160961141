import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import resolvers from './resolvers'

Vue.use(VueApollo)

const cache = new InMemoryCache()
const httpLink = createHttpLink({
  uri: `${process.env.VUE_APP_API_URL}/data`,
  credentials: 'include',
})

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  resolvers,
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})
