import { Roles } from '../../../../shared/constants'

export default () => ({
  path: 'upload',
  name: 'operations-upload',
  component: () => import(/* webpackChunkName: "operations-upload" */ '../../../views/Operations/OperationsUpload'),
  meta: {
    title: 'Upload',
    allowedUserRoles: [Roles.RADAR_OPS, Roles.RADAR_TEST, Roles.RADAR_VIEW],
  },
})
