import { Roles } from '../../../../shared/constants'

export default () => ({
  path: 'plans',
  name: 'data-plans',
  component: () => import(/* webpackChunkName: "data-plans" */ '../../../views/Data/DataPlans'),
  meta: {
    title: 'Plans',
    allowedUserRoles: [Roles.RADAR_OPS, Roles.RADAR_TEST, Roles.RADAR_VIEW],
  },
})
