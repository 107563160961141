import { Roles } from '../../../../shared/constants'

export default () => ({
  path: '/data/plans/:id',
  name: 'data-plans-detail',
  component: () => import(/* webpackChunkName: "data-plans-detail" */ '../../../views/Data/DataPlanDetails'),
  meta: {
    allowedUserRoles: [Roles.RADAR_OPS, Roles.RADAR_TEST, Roles.RADAR_VIEW],
    hideNavigation: true,
  },
})
