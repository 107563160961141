// Allowed page names
export const OPERATIONS_HISTORICAL_NAME = 'operationsHistorical'
export const DATA_PLANS_NAME = 'dataPlans'
export const DATA_PLANS_DETAILS_NAME = 'dataPlansDetails'

// Filters by page name map
export const PAGE_FILTERS_MAP = {
  [OPERATIONS_HISTORICAL_NAME]: [
    'selectedProvider',
    'selectedFiduciary',
    'selectedProcessMethod',
    'selectedProcessType',
    'selectedDateRange',
    'selectedFileExtension',
    'selectedProcessStage',
    'selectedProcessStatus',
  ],
  [DATA_PLANS_NAME]: [
    'selectedFiduciary',
    'selectedProvider',
    'selectedProgram',
    'selectedProduct',
    'selectedMenu',
    'selectedStatus',
    'selectedEndDate',
    'selectedUpdateRange',
  ],
  [DATA_PLANS_DETAILS_NAME]: [
    'selectedClientFundCode',
    'selectedSecId',
    'selectedFiduciaryCategory',
    'selectedFundStatus',
    'selectedComplianceClientFundCode',
    'selectedErrorCode',
  ],
}

// Process Methods Values
export const PROCESS_METHODS = [
  'API',
  'FTP',
  'Radar',
]

// Process Stages Values
export const PROCESS_STAGES = [
  'Received',
  'Queued',
  'Initiated',
  'Finished',
  'Terminated',
]

// Process Stages Values
export const PROCESS_STATUS = [
  'Success',
  'Partial',
  'Failed',
  'Schema Failed',
]

// Process Types Values
export const PROCESS_TYPES = [
  'Fund Universe',
  'Plans',
  'Compliance - Menu',
]

// File Extension Values
export const FILE_EXTENSIONS = [
  'XML',
  'CSV',
  'XLSX',
  'XLS',
  'TXT',
]
