class NotificationService {
  constructor (event) {
    this.event = event
  }

  /**
   * "options" can be either a string or object.
   * If a string, "options" will be the "text" value of the MDS Notification.
   * This should be used for simple notifications where only a message is required.
   * If an object, "options" will be a set of MDS Notification props defined here:
   * https://designsystem.morningstar.com/components/notification
   *
   * That means that the following are equivalent:
   * error('my message')
   * error({ text: 'my message' })
   */
  error (options, id) {
    this._addNotification('error', options, id)
  }

  warning (options, id) {
    this._addNotification('warning', options, id)
  }

  success (options, id) {
    this._addNotification('success', options, id)
  }

  informational (options, id) {
    this._addNotification('informational', options, id)
  }

  subscribe (handler) {
    this.event.$on('notification', data => handler(data))
  }

  _addNotification (type, options, id = generateId()) {
    if (!type || !options) {
      throw new Error('"type" and "options" are required.')
    }

    if (typeof options === 'string') {
      this.event.$emit('notification', { id, variation: type, text: options })
    } else {
      this.event.$emit('notification', { id, variation: type, ...options })
    }
  }
}

const generateId = () => Math.random().toString(36).substr(2, 9)

export default NotificationService
