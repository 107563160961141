<template>
  <mds-page-shell
    :logo="`${publicPath}images/logo.svg`"
    :logo-responsive="`${publicPath}images/logo.svg`"
    logo-alt="Morningstar Radar"
    sticky
    class="radar"
  >
    <mds-dialog
      v-if="isSelectViewDialogEnabled"
      v-model="toggleDialog"
      action-required
      width="500px"
      title="Select View"
    >
      <!-- Default slot -->
      <div class="data-select-message">
        Please select which data category you want to view. Changing data category requires to reload the site, any work in progress will be lost upon reload.
      </div>
      <mds-form>
        <mds-fieldset
          variation="radio-group"
        >
          <div
            v-if="isAllOptionEnabled"
            class="radio-container"
          >
            <mds-radio-button
              v-model="selectedDataView"
              name="dataViewOption"
              :value="VIEWS.ALL"
              checked
            >
              View All Data
            </mds-radio-button>
          </div>
          <div class="radio-container">
            <mds-radio-button
              v-model="selectedDataView"
              name="dataViewOption"
              :value="VIEWS.LIVE"
            >
              View Live Data
            </mds-radio-button>
          </div>
          <div class="radio-container">
            <mds-radio-button
              v-model="selectedDataView"
              name="dataViewOption"
              :value="VIEWS.TEST"
            >
              View Test Data
            </mds-radio-button>
          </div>
        </mds-fieldset>
      </mds-form>
      <template v-slot:mds-dialog-actions-right>
        <mds-button-container right-aligned>
          <mds-button
            v-if="isDataViewSet"
            type="button"
            variation="secondary"
            @click="isDialogOpen=false"
          >
            Cancel
          </mds-button>
          <mds-button
            type="button"
            variation="primary"
            @click="setView"
          >
            Continue
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>
    <template v-slot:mds-page-shell-masthead-left>
      <fade-transition>
        <mds-button
          v-if="isDataViewSet"
          class="data-view-button"
          :class="{
            'disabled-view': !isSelectViewDialogEnabled,
          }"
          variation="flat"
          @click="isDialogOpen = !isDialogOpen"
        >
          <mds-icon
            class="data-view-icon"
            name="eye"
          />
          <span class="selected-view-text">{{ selectedViewText }}</span>
        </mds-button>
      </fade-transition>
    </template>
    <template v-slot:mds-page-shell-masthead-right>
      <fade-transition>
        <mds-button
          v-if="signoutLink"
          class="signout-link"
          variation="flat"
          :href="signoutLink"
          @click="onSignOutClick"
        >
          Sign Out
        </mds-button>
      </fade-transition>
    </template>
    <template v-slot:mds-page-shell-horizontal-nav>
      <mds-tabs
        v-if="showNavigation"
        :content="topLevelTabs"
        alignment="center"
        size="large"
        variation="site-navigation"
      />
    </template>
    <mds-notification-container
      v-show="notifications.length"
      masthead-padding="below-masthead-tall"
    >
      <mds-notification
        v-for="notification in notifications"
        :key="notification.id"
        v-bind="notification"
        @mds-notification-dismissed="removeNotification(notification.id)"
      >
        {{ notification.text }}
      </mds-notification>
    </mds-notification-container>

    <div class="main-container">
      <fade-transition
        v-if="isDataViewSet"
        duration="1-and-a-half-x"
      >
        <router-view :key="dataReload" />
      </fade-transition>
    </div>

    <footer>
      <p>&copy; {{ currentYear }} Morningstar. All Rights Reserved.</p>
    </footer>
  </mds-page-shell>
</template>

<script>
import FadeTransition from './components/Transitions/FadeTransition'
import { MdsButton, MdsButtonContainer } from '@mds/button'
import MdsDialog from '@mds/dialog'
import MdsPageShell from '@mds/page-shell'
import MdsTabs from '@mds/tabs'
import MdsForm from '@mds/form'
import MdsFieldset from '@mds/fieldset'
import MdsRadioButton from '@mds/radio-button'
import MdsIcon from '@mds/icon'
import { MdsNotificationContainer, MdsNotification } from '@mds/notification'
import { GET_SESSION_CONFIG, GET_USER } from './graqhql/queries'
import { SWITCH_VIEW_EVENT_EMITTER } from '../shared/constants/event-emitters'
import { RadarViews } from '../shared/constants/radar-views'
import Roles from '../shared/constants/roles'
import RoleAccess from './services/role-access-service'
import { hasCommonElement } from '../shared/utils'
import { isViewValid, isViewValidForEdit, enableSelectViewDialog } from '../shared/viewRolesActions'

// TODO: Figure out route undefined issue

const INITIAL_SELECTED_VIEW_VALUE = localStorage.getItem('selectedView')
const VIEW_SELECTION_TEXT = {
  [RadarViews.ALL]: 'All Data',
  [RadarViews.LIVE]: 'Live Data',
  [RadarViews.TEST]: 'Test Data',
}

export default {
  components: {
    FadeTransition,
    MdsButton,
    MdsButtonContainer,
    MdsDialog,
    MdsForm,
    MdsFieldset,
    MdsRadioButton,
    MdsIcon,
    MdsPageShell,
    MdsTabs,
    MdsNotificationContainer,
    MdsNotification,
  },
  apollo: {
    sessionConfig: {
      query: GET_SESSION_CONFIG,
      variables: { clientOrigin: window.location.origin },
    },
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      sessionConfig: null,
      notifications: [],
      selectedDataView: INITIAL_SELECTED_VIEW_VALUE || RadarViews.ALL,
      VIEWS: RadarViews,
      selectedUserView: INITIAL_SELECTED_VIEW_VALUE,
      isDialogOpen: false,
      user: null,
      dataReload: 0,
      isSelectViewDialogEnabled: false,
    }
  },
  computed: {
    currentlySelectedTopLevelRoute () {
      return this.$route?.matched[0]
    },
    topLevelRoutes () {
      return this.$router.options.routes.filter(route => route.meta && route.meta.main)
    },
    topLevelTabs () {
      return this.topLevelRoutes.map(route => ({
        text: route.meta.title,
        href: this.$router.resolve({ name: route.name }).href,
        active: route.name === this.currentlySelectedTopLevelRoute?.name,
      }))
    },
    currentYear () {
      return new Date().getFullYear()
    },
    showNavigation () {
      // May need to revisit this logic
      return !this.$route.meta.hideNavigation
    },
    signoutLink () {
      return this.sessionConfig?.signoutUrl
    },
    isUserExist () {
      return !!this.user
    },
    isDataViewSet () {
      return !!this.selectedUserView
    },
    userRoles () {
      return this.isUserExist && this.user.roles
    },
    isAllOptionEnabled () {
      return enableSelectViewDialog(this.userRoles).isAllOptionEnabled
    },
    toggleDialog: {
      // Add getter and setter for computed property as this property is being used as v-modal for Dialog
      get () {
        return (this.isUserExist && !this.selectedUserView && this.isSelectViewDialogEnabled) || (this.isDialogOpen && this.isSelectViewDialogEnabled)
      },
      set (newVal) {
        return newVal
      },
    },
    selectedViewText () {
      return VIEW_SELECTION_TEXT[this.selectedUserView]
    },
  },
  mounted () {
    // Emit event on root component to switch Selected View from Dashboard Quick Link
    this.$root.$on(SWITCH_VIEW_EVENT_EMITTER, this.switchViewFromQuickLink)

    this.$notifications.subscribe(this.addNotification)
    this.setInitialView()
  },
  beforeDestroy () {
    this.$root.$off(SWITCH_VIEW_EVENT_EMITTER, this.switchViewFromQuickLink)
  },
  methods: {
    async setInitialView () {
      const { data: { user } } = await this.$apollo.query({
        query: GET_USER,
      })

      // assign GET_USER query result to global user variable
      this.user = user

      // enables or disables Select View Dialog based on the combination of user's roles
      this.isSelectViewDialogEnabled = enableSelectViewDialog(user.roles).isDialogEnabled

      // set initial selected view
      if (!this.isSelectViewDialogEnabled) {
        if (hasCommonElement([Roles.RADAR_OPS], user.roles)) {
          this.selectedUserView = this.VIEWS.LIVE
        } else if (hasCommonElement([Roles.RADAR_TEST], user.roles)) {
          this.selectedUserView = this.VIEWS.TEST
        }
        this.reloadView()
      } else if (!localStorage.getItem('selectedView')) {
        this.isDialogOpen = true
      }
    },
    setView () {
      // Detect switch between Test or Live Views to clear provider filters
      const isViewChanged = (this.selectedDataView !== this.selectedUserView) &&
      (this.selectedDataView !== this.VIEWS.ALL)

      this.selectedUserView = this.selectedDataView
      this.isDialogOpen = false
      this.reloadView({ isViewChanged })
    },
    switchViewFromQuickLink (newSelectedView) {
      if (((newSelectedView === this.VIEWS.ALL) && !this.isAllOptionEnabled) || !isViewValid(this.userRoles, newSelectedView)) {
        this.$notifications.error({
          text: `You do not have the appropriate role to access ${VIEW_SELECTION_TEXT[newSelectedView]} view.`,
          tinted: true,
          dismissDelay: 5000,
        })
      } else {
        this.selectedDataView = newSelectedView
        this.selectedUserView = newSelectedView
        this.reloadView()
      }
    },
    reloadView ({ isViewChanged = false } = {}) {
      if (localStorage.getItem('selectedView')) {
        localStorage.setItem('selectedView', this.selectedUserView)
        this.dataReload += 1
      } else {
        localStorage.setItem('selectedView', this.selectedUserView)
      }

      sessionStorage.setItem('isViewChanged', isViewChanged)
      RoleAccess.selectedView = this.selectedUserView
      RoleAccess.disableEdit = !isViewValidForEdit(this.userRoles, this.selectedUserView)
    },
    addNotification (notification) {
      // This variable checks if the notification is already present to avoid duplicated messages
      const isNotificationPresent = this.notifications.some((item) => (item.variation === notification.variation) && (item.text === notification.text))
      if (!isNotificationPresent) {
        this.notifications.push(notification)
      }
    },
    removeNotification (notificationId) {
      this.notifications = this.notifications.filter(notification => notification.id !== notificationId)
    },
    onSignOutClick () {
      localStorage.removeItem('selectedView')
    },
  },
}
</script>

<style lang="scss">
@import '@mds/constants';
@import '@mds/icon/src/icon_mixins';

:root {
  --color--brand: #1E53AC;
  --color--background: var(--color--brand);
  --space--10-x: 80px;
  --shadow--panel: 0 32px 64px -40px rgba(0, 0, 0, .5);

  --grid--main-layout-max-width: 1308px;
  --grid--main-layout-gap: 16px;
  --grid--main-layout-padded-column: calc(50vw - (var(--grid--main-layout-max-width) / 2) - var(--grid--main-layout-gap));
  --grid--main-layout-columns: var(--grid--main-layout-padded-column) 1fr var(--grid--main-layout-padded-column);
}

body {
  @include mds-body-text-m();
  color: $mds-text-color-primary;
  background-color: var(--color--background);
}

// TODO: come back to responsive styles
.main-container {
  display: grid;
  align-items: start;
  grid-template-columns: var(--grid--main-layout-columns);
  gap: 0 var(--grid--main-layout-gap);
  padding: $mds-space-8-x 0 var(--space--10-x);
  min-height: 80vh;

  > * {
    grid-column: 2 / span 1;
  }
}

.data-view-icon.mds-icon___radar {
  @include mds-icon-color($mds-interactive-color-primary-default);
}

.data-view-button.disabled-view {
  pointer-events: none;
}

.radio-container {
  margin: 0 0 15px 0;
}

.data-select-message {
  margin: 0 0 20px 0;
}

.selected-view-text {
  vertical-align: super;
  margin: 0 0 0 5px;
}

footer {
  @include mds-body-text-s();
  display: grid;
  grid-template-columns: var(--grid--main-layout-columns);
  gap: 0 var(--grid--main-layout-gap);
  padding: $mds-space-4-x 0 $mds-space-6-x;
  color: $mds-text-color-secondary;
  background-color: $mds-background-color-white;

  > p {
    grid-column: 2 / span 1;
  }
}
</style>

<style lang="scss" module>
:global(.radar) {
  // TODO: remove header z-index when update MDS data-table to v4
  .mds-page-shell__header {
    z-index: 99999;
  }

  .mds-page-shell__masthead-logo-wide,
  .mds-page-shell__masthead-logo {
    width: auto;
    height: 45px;
  }

  .mds-page-shell__masthead-left {
    .mds-button {
      color:#0077cf;
    }
  }

  .mds-page-shell__content {
    .mds-notification-container--below-masthead-tall {
      padding-top: 100px;
    }
  }
}
</style>
