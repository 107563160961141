import { Roles } from '../../../../shared/constants'

export default () => ({
  path: 'compliance',
  name: 'operations-compliance',
  component: () => import('../../../views/Operations/OperationsCompliance'),
  meta: {
    title: 'Compliance',
    allowedUserRoles: [Roles.RADAR_OPS, Roles.RADAR_TEST, Roles.RADAR_VIEW],
  },
})
