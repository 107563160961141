/**
 * The FileService class is meant to be a collection of helpers to assist
 * in downloading various types of files from the client.
 */

import { saveAs } from 'file-saver'

class FileService {
  downloadBlobFile (name, data) {
    const url = URL.createObjectURL(new Blob([data]))
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.setAttribute('download', name)
    document.body.appendChild(anchor)
    anchor.click()
    anchor.parentNode.removeChild(anchor)
    URL.revokeObjectURL(url)
  }

  /**
   * Downloads a file with a given name.
   *
   * @param {Blob/File/Url} data
   * @param {String} name
   *
   * See: https://github.com/eligrey/FileSaver.js
   */
  download (data, name) {
    saveAs(data, name)
  }
}

export default FileService
