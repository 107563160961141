import getSessionConfig from './get-session-config.gql'
import getUser from './get-user.gql'
import getProviders from './get-providers.gql'
import getLiveProviders from './get-live-providers.gql'
import getIsLiveProvider from './get-is-live-provider.gql'
import getDashboardProviders from './get-dashboard-providers.gql'
import getDashboardProviderId from './get-dashboard-provider-id.gql'
import getDashboardQuickLinks from './get-dashboard-quick-links.gql'
import getDashboardUserId from './get-dashboard-user-id.gql'
import getDashboardMyDetails from './get-dashboard-my-details.gql'
import getClientFundCodes from './get-client-fund-codes.gql'
import getComplianceClientFundCodes from './get-compliance-client-fund-codes.gql'
import getComplianceRun from './get-compliance-run.gql'
import getErrorCodes from './get-error-codes.gql'
import getFiduciaryCategories from './get-fiduciary-categories.gql'
import getFundStatus from './get-fund-status.gql'
import getSecIds from './get-sec-ids.gql'
import getImports from './get-imports.gql'
import getImportsCount from './get-imports-count.gql'
import getImportDetails from './get-import-details.gql'
import getImportSourcefiles from './get-import-sourcefiles.gql'
import getImportTransformedFile from './get-import-transformed-file.gql'
import getImportUpload from './get-import-upload.gql'
import getPlans from './get-plans.gql'
import getPlansCount from './get-plans-count.gql'
import getPlanDetails from './get-plan-details.gql'
import getPlanCompliancesCount from './get-plan-compliances-count.gql'
import getPlanCompliancesData from './get-plan-compliances-data.gql'
import getPlanFundsData from './get-plan-funds-data.gql'
import getPlanFundsCount from './get-plan-funds-count.gql'
import getFiduciaries from './get-fiduciaries.gql'
import getFiduciariesFromProvider from './get-fiduciaries-from-provider.gql'
import getPrograms from './get-programs.gql'
import getPlanStatuses from './get-plan-statuses.gql'
import getProducts from './get-products.gql'
import getMenus from './get-menus.gql'
import getQuarterEndWindow from './get-quarter-end-window.gql'
import getDashboardIfExistsMyDetails from './get-dashboard-if-exists-my-details.gql'

export const GET_SESSION_CONFIG = getSessionConfig
export const GET_USER = getUser
export const GET_PROVIDERS = getProviders
export const GET_LIVE_PROVIDERS = getLiveProviders
export const GET_IS_LIVE_PROVIDER = getIsLiveProvider
export const GET_DASHBOARD_PROVIDERS = getDashboardProviders
export const GET_DASHBOARD_WATCH_LIST_PROVIDER_ID = getDashboardProviderId
export const GET_DASHBOARD_QUICK_LINKS = getDashboardQuickLinks
export const GET_DASHBOARD_USER_ID = getDashboardUserId
export const GET_DASHBOARD_MY_DETAILS = getDashboardMyDetails
export const GET_DASHBOARD_IF_EXISTS_MY_DETAILS = getDashboardIfExistsMyDetails
export const GET_CLIENT_FUND_CODES = getClientFundCodes
export const GET_COMPLIANCE_CLIENT_FUND_CODES = getComplianceClientFundCodes
export const GET_COMPLIANCE_RUN = getComplianceRun
export const GET_ERROR_CODES = getErrorCodes
export const GET_FIDUCIARY_CATEGORIES = getFiduciaryCategories
export const GET_FUND_STATUS = getFundStatus
export const GET_SEC_IDS = getSecIds
export const GET_IMPORTS = getImports
export const GET_IMPORTS_COUNT = getImportsCount
export const GET_IMPORT_DETAILS = getImportDetails
export const GET_IMPORT_SOURCEFILES = getImportSourcefiles
export const GET_IMPORT_TRANSFORMED_FILE = getImportTransformedFile
export const GET_IMPORT_UPLOAD = getImportUpload
export const GET_PLANS = getPlans
export const GET_PLANS_COUNT = getPlansCount
export const GET_PLAN_DETAILS = getPlanDetails
export const GET_PLAN_COMPLIANCES_COUNT = getPlanCompliancesCount
export const GET_PLAN_COMPLIANCES_DATA = getPlanCompliancesData
export const GET_PLAN_FUNDS_DATA = getPlanFundsData
export const GET_PLAN_FUNDS_COUNT = getPlanFundsCount
export const GET_FIDUCIARIES = getFiduciaries
export const GET_FIDUCIARIES_FROM_PROVIDER = getFiduciariesFromProvider
export const GET_PROGRAMS = getPrograms
export const GET_PLAN_STATUSES = getPlanStatuses
export const GET_PRODUCTS = getProducts
export const GET_MENUS = getMenus
export const GET_QUARTER_END_WINDOW = getQuarterEndWindow
