import { format, formatISO, parse, parseISO } from 'date-fns'

export const chunk = (input, size) => {
  return input.reduce((array, item, index) => {
    return index % size === 0
      ? [...array, [item]]
      : [...array.slice(0, -1), [...array.slice(-1)[0], item]]
  }, [])
}

/**
 * Inverts the keys and values of an object.
 *
 * @param {object} object The object to invert
 * @returns {object} Returns the new inverted object
 */
export const invert = (object) => {
  return Object.entries(object)
    .reduce((newObj, [key, value]) => ({ ...newObj, [value]: key }), {})
}

/**
 * Renames keys of object with a new map of keys.
 *
 * @param {object} object The object with keys to rename
 * @param {object} newKeys The map of new key names
 * @returns {object} Returns the new object with updated key names
 */
export const renameKeys = (object, newKeys) => {
  return Object.keys(object).reduce((result, currentKey) => {
    const newKey = newKeys[currentKey] || currentKey
    result[newKey] = object[currentKey]
    return result
  }, {})
}

/**
 * Transforms keys of object.
 *
 * @param {object} object The object with keys to rename
 * @param {transform} function The transform function run on each key
 * @param {exceptions} array Optional - The array of exceptions of keys that should not be renamed
 * @returns {object} Returns the new object with transformed key names
 */
export const transformObjectKeys = (object, transform, exceptions) => {
  return Object.entries(object).reduce((result, [key, value]) => {
    exceptions?.length && exceptions?.includes(key) ? result[key] = value : result[transform(key)] = value
    return result
  }, {})
}

/**
 * Formats a date string in ISO format
 *
 * @param {string} dateAsIsoString The ISO string to be formatted
 * @param {string} dateFormat The format token
 * @returns {string} Returns the formatted date string
 */
export const formatDateFromIsoString = (dateAsIsoString, dateFormat) => {
  return format(parseISO(dateAsIsoString), dateFormat)
}

/**
 * Takes formatted date string and returns ISO string
 *
 * @param {string} formattedDate The formatted date string
 * @param {string} existingFormat The formatted date string format token
 * @returns {string} Returns ISO string
 */
export const formatIsoFromDateString = (formattedDate, existingFormat) => {
  return formatISO(parse(formattedDate, existingFormat, new Date()), { representation: 'date' })
}

/**
 * Checks if two arrays share a common element
 *
 * @param {array} array1
 * @param {array} array2
 * @returns {boolean}
 */
export const hasCommonElement = (array1, array2) => {
  return array1.some(element => array2.includes(element))
}

/**
 * Checks if two arrays share all elements
 *
 * @param {array} array1
 * @param {array} array2
 * @returns {boolean}
 */
export const hasAllElements = (array1, array2) => {
  return array1.every(element => array2.includes(element))
}

/**
 * Generates a random ID
 */
export const generateRandomId = () => {
  return Math.random().toString(36).substring(2, 9)
}
