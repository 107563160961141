// "Enum"
const RadarRoles = Object.freeze({
  RADAR_ADMIN: 'FIDUCIARYSERVICES_RADAR_ADMIN',
  RADAR_OPS: 'FIDUCIARYSERVICES_RADAR_OPS',
  RADAR_TEST: 'FIDUCIARYSERVICES_RADAR_TEST',
  RADAR_VIEW: 'FIDUCIARYSERVICES_RADAR_VIEW',
  RADAR_OPS_LEGACY: 'FIDUCIARYSERVICES_OPERATIONS', // this is used only to display "unauthorized route" to this role
})

// "Enum"
const ConnectRoles = Object.freeze({
  CONNECT_ADMIN: 'FIDUCIARYSERVICES_CONNECT_ADMIN',
  CONNECT_OPS: 'FIDUCIARYSERVICES_CONNECT_OPS',
  CONNECT_TEST: 'FIDUCIARYSERVICES_CONNECT_TEST',
  CONNECT_VIEW: 'FIDUCIARYSERVICES_CONNECT_VIEW',
  CONNECT_CONSULTANT: 'FIDUCIARYSERVICES_CONSULTANT', // Will eventually be sunsetted
})

// "Enum"
export default Object.freeze({
  ...RadarRoles,
  ...ConnectRoles,
})
