<template>
  <transition
    :enter-active-class="enterActiveClass"
    :leave-active-class="leaveActiveClass"
    :mode="mode"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  props: {
    easing: {
      type: String,
      default: 'ease-in-out',
      validator: value => ['ease-in', 'ease-out', 'ease-in-out'].includes(value),
    },
    duration: {
      type: String,
      default: '1-x',
      validator: value => ['half-x', '1-x', '1-and-a-half-x', '2-x', '2-and-a-half-x'].includes(value),
    },
    mode: {
      type: String,
      default: 'out-in',
      validator: value => ['out-in', 'in-out'].includes(value),
    },
  },
  computed: {
    enterActiveClass () {
      return `transition-fade-in transition-fade-in--${this.easing} transition-fade-in--${this.duration}`
    },
    leaveActiveClass () {
      return `transition-fade-out transition-fade-out--${this.easing} transition-fade-out--${this.duration}`
    },
  },
}
</script>

<style lang="scss">
@keyframes transition-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes transition-fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}

.transition-fade-in {
  animation-name: transition-fade-in;

  &--ease-in {
    animation-timing-function: $mds-animation-ease-in;
  }

  &--ease-out {
    animation-timing-function: $mds-animation-ease-out;
  }

  &--ease-in-out {
    animation-timing-function: $mds-animation-ease-in-out;
  }

  &--half-x {
    animation-duration: $mds-animation-timing-half-x;
  }

  &--1-x {
    animation-duration: $mds-animation-timing-1-x;
  }

  &--1-and-a-half-x {
    animation-duration: $mds-animation-timing-1-and-a-half-x;
  }

  &--2-x {
    animation-duration: $mds-animation-timing-2-x;
  }

  &--2-and-a-half-x {
    animation-duration: $mds-animation-timing-2-and-a-half-x;
  }
}

.transition-fade-out {
  animation-name: transition-fade-out;

  &--ease-in {
    animation-timing-function: $mds-animation-ease-in;
  }

  &--ease-out {
    animation-timing-function: $mds-animation-ease-out;
  }

  &--ease-in-out {
    animation-timing-function: $mds-animation-ease-in-out;
  }

  &--half-x {
    animation-duration: $mds-animation-timing-half-x;
  }

  &--1-x {
    animation-duration: $mds-animation-timing-1-x;
  }

  &--1-and-a-half-x {
    animation-duration: $mds-animation-timing-1-and-a-half-x;
  }

  &--2-x {
    animation-duration: $mds-animation-timing-2-x;
  }

  &--2-and-a-half-x {
    animation-duration: $mds-animation-timing-2-and-a-half-x;
  }
}
</style>
