// Allowed validation statuses
export const SUCCESS = 'SUCCESS'
export const PARTIAL = 'PARTIAL'
export const FAILED = 'FAILED'
export const SCHEMA_FAILED = 'SCHEMA_FAILED'

// Type to copy map
export const VALIDATION_STATUS_COPY_MAP = {
  [SUCCESS]: 'Success',
  [PARTIAL]: 'Partial',
  [FAILED]: 'Failed',
  [SCHEMA_FAILED]: 'Schema Failed',
}
