import { Roles } from '../../../../shared/constants'

export default () => ({
  path: '/operations/historical/:id',
  name: 'operations-historical-detail',
  component: () => import(/* webpackChunkName: "operations-historical-detail" */ '../../../views/Operations/OperationsHistoricalDetail'),
  meta: {
    allowedUserRoles: [Roles.RADAR_OPS, Roles.RADAR_TEST, Roles.RADAR_VIEW],
    hideNavigation: true,
  },
})
