import { Roles } from '../../../../shared/constants'

export default () => ({
  path: '/configurations/quarter-end',
  name: 'configurations-quarter-end',
  component: () => import(/* webpackChunkName: "configurations-quarter-end" */ '../../../views/Configurations/ConfigurationsQuarterEnd'),
  meta: {
    main: true,
    title: 'Configurations',
    allowedUserRoles: [Roles.RADAR_OPS, Roles.RADAR_TEST, Roles.RADAR_VIEW],
  },
})
