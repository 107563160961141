import { Roles } from '../../../../shared/constants'

export default () => ({
  path: '/dashboard',
  name: 'dashboard',
  component: () => import(/* webpackChunkName: "data-root" */ '../../../views/Dashboard/Dashboard.vue'),
  meta: {
    main: true,
    title: 'Dashboard',
    allowedUserRoles: [
      Roles.RADAR_ADMIN,
      Roles.RADAR_OPS,
      Roles.RADAR_TEST,
      Roles.RADAR_VIEW,
    ],
  },
})
