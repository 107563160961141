import Vue from 'vue'
import Router from 'vue-router'

import routes from './routes'
import authGuard from './guards/auth-guard'

Vue.use(Router)

const router = ({ store } = {}) => {
  const router = new Router({
    routes: routes({ store }),
    scrollBehavior (to, from, savedPosition) {
      return { x: 0, y: 0 }
    },
  })

  authGuard({ router, store })
  return router
}

export default router
