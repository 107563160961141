import operationsHistoricalRoute from './operations-historical-route'
import operationsUploadRoute from './operations-upload-route'
import operationsComplianceRoute from './operations-compliance-route'
import { Roles } from '../../../../shared/constants'

export default () => ({
  path: '/operations',
  name: 'operations',
  component: () => import(/* webpackChunkName: "operations-root" */ '../../../views/Operations/OperationsRoot.vue'),
  meta: {
    main: true,
    title: 'Operations',
    allowedUserRoles: [
      Roles.RADAR_ADMIN,
      Roles.RADAR_OPS,
      Roles.RADAR_TEST,
      Roles.RADAR_VIEW,
    ],
  },
  redirect: { name: 'operations-upload' },
  children: [
    operationsUploadRoute(),
    operationsComplianceRoute(),
    operationsHistoricalRoute(),
  ],
})
