import { GET_USER, GET_SESSION_CONFIG } from '../../graqhql/queries'
import { hasCommonElement } from '../../../shared/utils'

export async function beforeEach (to, from, next, { store }) {
  const { data: { user } } = await store.query({ query: GET_USER })
  const userExists = !!user
  const routeRequiresAuthorization = !!(to?.meta?.allowedUserRoles?.length)
  const userAllowedToAccessRoute = routeRequiresAuthorization
    ? !!(user?.roles?.length) && hasCommonElement(to.meta.allowedUserRoles, user.roles)
    : true

  if (!userExists) {
    // Get session config
    const { data: { sessionConfig } } = await store.query({ query: GET_SESSION_CONFIG, variables: { clientOrigin: window.location.origin } })
    // Navigate to the Central Sign In page: https://mswiki.morningstar.com/display/technology/Auth0+Migrations+Guide+for+Central+Sign-In+Flow
    window.location.replace(sessionConfig?.signinUrl)
  } else {
    // User exists
    return userAllowedToAccessRoute ? next() : next({ name: 'unauthorized' })
  }
}

export default ({ router, store }) => {
  router.beforeEach((to, from, next) => beforeEach(to, from, next, { store }))
}
