import { Roles } from '../../../shared/constants'

export default () => ({
  path: '/unauthorized',
  name: 'unauthorized',
  component: () => import(/* webpackChunkName: "unauthorized" */ '../../views/Unauthorized.vue'),
  meta: {
    hideNavigation: true,
    allowedUserRoles: [
      Roles.RADAR_ADMIN,
      Roles.RADAR_OPS,
      Roles.RADAR_TEST,
      Roles.RADAR_VIEW,
      Roles.RADAR_OPS_LEGACY,
    ],
  },
})
