import { hasCommonElement, hasAllElements } from '../shared/utils'
import Roles from '../shared/constants/roles'
import { RadarViews } from '../shared/constants/radar-views'

/**
 * Checks whether user has appropriate roles to get data as per selected view and returns boolean flag
 *
 * @param {array} userRoles Array of User's role
 * @param {ArrayBufferConstructor} selectedView Selected view by user
 * @returns {boolean} Returns the formatted date string
 */

export const isViewValid = (userRoles, selectedView) => {
  let isValid = false

  if (selectedView === RadarViews.ALL) {
    isValid = hasAllElements([Roles.RADAR_TEST, Roles.RADAR_OPS], userRoles)
  } else if (selectedView === RadarViews.TEST) {
    isValid = hasCommonElement([Roles.RADAR_TEST, Roles.RADAR_VIEW], userRoles)
  } else if (selectedView === RadarViews.LIVE) {
    isValid = hasCommonElement([Roles.RADAR_OPS, Roles.RADAR_VIEW], userRoles)
  }
  return isValid
}

/**
 * Checks whether user has appropriate roles to edit data as per selected view and returns boolean flag
 *
 * @param {array} userRoles Array of User's role
 * @param {ArrayBufferConstructor} selectedView Selected view by user
 * @returns {boolean} Returns the formatted date string
 */
export const isViewValidForEdit = (userRoles, selectedView) => {
  let isValid = false

  if (selectedView === RadarViews.ALL) {
    isValid = hasAllElements([Roles.RADAR_TEST, Roles.RADAR_OPS], userRoles)
  } else if (selectedView === RadarViews.TEST) {
    isValid = hasCommonElement([Roles.RADAR_TEST], userRoles)
  } else if (selectedView === RadarViews.LIVE) {
    isValid = hasCommonElement([Roles.RADAR_OPS], userRoles)
  }
  return isValid
}

/**
 * Checks whether user has appropriate roles to select views and enable 'View All' option in Select View dialog and returns boolean flags
 *
 * @param {array} userRoles Array of User's role
 * @returns {object} Returns an object with isDialogEnabled and isAllOptionEnabled boolean flags
 */
export const enableSelectViewDialog = (userRoles) => {
  let isDialogEnabled = false
  let isAllOptionEnabled = false

  if (hasAllElements([Roles.RADAR_TEST, Roles.RADAR_OPS], userRoles)) {
    isDialogEnabled = true
    isAllOptionEnabled = true
  } else if (hasAllElements([Roles.RADAR_TEST || Roles.RADAR_OPS, Roles.RADAR_VIEW], userRoles)) {
    isDialogEnabled = true
  } else if (hasCommonElement([Roles.RADAR_VIEW], userRoles)) {
    isDialogEnabled = true
  }
  return { isDialogEnabled, isAllOptionEnabled }
}
